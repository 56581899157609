<template>
  <b-row>
    <b-col
      v-if="language"
      cols="12"
      class="mb-2"
    >
      {{ getSpecificLocale(language).locale }}
      <b-img
        :src="getSpecificLocale(language).img"
        width="22"
        height="14"
      />
    </b-col>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('Current value') }}
      </label>
      <template v-if="oldValue">
        <b-list-group>
          <b-form-textarea
            v-if="request.field_name === 'mentor_description'"
            :id="'i-current-value-' + request.id"
            v-model="oldValue[language]"
            rows="5"
            no-resize
            disabled
          />
          <b-list-group-item v-else>
            <span>
              {{ oldValue[language] }}
            </span>
          </b-list-group-item>
        </b-list-group>
      </template>
    </b-col>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('New value') }}
      </label>
      <template v-if="newValue">
        <validation-observer ref="pendingEditRequestMentoringLanguagesValuesObserver">
          <b-list-group>
            <b-list-group-item style="border: 0; padding: 0">
              <validation-provider
                v-slot="{ errors }"
                name="newValue[language]"
                rules="required|max:1400"
                mode="lazy"
              >
                <textarea-with-counter
                  v-if="request.field_name === 'mentor_description'"
                  :id="'i-mentor-description' + request.id"
                  v-model="newValue[language]"
                  :locale="language"
                  :rows="5"
                  :max-chars="1400"
                />
                <b-form-input
                  v-else
                  :id="'i-new-value-' + request.id"
                  v-model="newValue[language]"
                  class="language-item-text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-list-group-item>
          </b-list-group>
        </validation-observer>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormInput,
  BFormTextarea,
  BImg,
  BListGroup,
  BListGroupItem,
  BRow
} from 'bootstrap-vue'
import { useLocales } from '@mentoring-platform/composables'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TextareaWithCounter from '@mentoring-platform/views/components/blocks/TextareaWithCounter.vue'

export default {
  components: {
    BCol,
    BFormInput,
    BFormTextarea,
    BImg,
    BListGroup,
    BListGroupItem,
    BRow,
    TextareaWithCounter,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    request: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      newValue: null,
      oldValue: null,
    }
  },
  computed: {
    language() {
      if (this.newValue) {
        return Object.keys(this.newValue)[0]
      }
      return null
    }
  },
  created() {
    this.oldValue = JSON.parse(this.request.old_value)
    this.newValue = JSON.parse(this.request.new_value)
  },
  methods: {
    isValid() {
      return true
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  },
  setup() {
    const { locales, getSpecificLocale } = useLocales()

    return {
      locales,
      getSpecificLocale
    }
  }
}
</script>

<style lang="scss" scoped>
.language-item-text {
  height: unset;
    padding: 0.75rem 1.25rem;
    font-size: 16px;
    line-height: 1.5;
}
</style>
