<template>
  <b-row>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('Current value') }}
      </label>
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in oldValue"
          :key="request.id + '_old_item_' + index"
        >
          {{ item }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('New value') }}
      </label>
      <validation-observer ref="pendingEditRequestListValuesObserver">
        <b-list-group>
          <b-list-group-item
            v-for="(item, index) in newValue"
            :key="request.id + '_new_item_' + index"
            style="border: 0; padding: 0"
          >
            <validation-provider
              v-slot="{ errors }"
              name="newValue[language]"
              :rules="fieldName==='mentor_links' ? 'required|url' : 'required'"
              mode="lazy"
            >
              <b-form-input
                :id="'i-new-value-' + index"
                v-model="newValue[index]"
                class="list-item-text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-list-group-item>
        </b-list-group>
      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BListGroup,
  BListGroupItem,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCol,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BRow,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      field_name: '',
      newValue: [],
      oldValue: [],
    }
  },
  created() {
    this.oldValue = this.request.old_value
    this.newValue = this.request.new_value
    this.fieldName = this.request.field_name
  },
  methods: {
    isValid() {
      return true
    },
    collectData() {
      return {
        new_value: this.newValue,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-item-text {
  height: unset;
  padding: 0.75rem 1.25rem;
  font-size: 16px;
  line-height: 1.5;
}
</style>
