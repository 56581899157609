var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('Current value'))+" ")]),(_vm.removal)?[_c('b-form-checkbox-group',{attrs:{"stacked":""},model:{value:(_vm.oldValue),callback:function ($$v) {_vm.oldValue=$$v},expression:"oldValue"}},_vm._l((_vm.locales),function(localeInfo,index){return _c('b-form-checkbox',{key:index,attrs:{"value":localeInfo.locale,"size":"lg","disabled":""}},[_vm._v(" "+_vm._s(localeInfo.name)+" "),_c('b-img',{attrs:{"src":localeInfo.img,"width":"22","height":"14"}})],1)}),1)]:_vm._e()],2),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('New value'))+" ")]),(_vm.removal)?[_c('b-form-checkbox-group',{attrs:{"stacked":""},model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}},_vm._l((_vm.locales),function(localeInfo,index){return _c('b-form-checkbox',{key:index,attrs:{"value":localeInfo.locale,"size":"lg","disabled":""}},[_vm._v(" "+_vm._s(localeInfo.name)+" "),_c('b-img',{attrs:{"src":localeInfo.img,"width":"22","height":"14"}})],1)}),1)]:(_vm.addition && _vm.newValue)?_vm._l((Object.keys(_vm.newValue)),function(language,index){return _c('div',{key:index},[_c('validation-observer',{ref:"pendingEditRequestMentoringLanguagesValuesObserver",refInFor:true},[_c('b-list-group',[_c('b-list-group-item',[_vm._v(" "+_vm._s(_vm.getSpecificLocale(language).locale)+" "),_c('b-img',{attrs:{"src":_vm.getSpecificLocale(language).img,"width":"22","height":"14"}})],1),_c('b-list-group-item',[_vm._v(" "+_vm._s(_vm.$t('Name'))),_c('br'),_c('validation-provider',{attrs:{"name":"newValue[language]","rules":"required|min:3|max:50","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'i-new-value-' + index,"size":"lg"},model:{value:(_vm.newValue[language].name),callback:function ($$v) {_vm.$set(_vm.newValue[language], "name", $$v)},expression:"newValue[language].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-list-group-item',[_vm._v(" "+_vm._s(_vm.$t('Surname'))),_c('br'),_c('validation-provider',{attrs:{"name":"newValue[language]","rules":"required|min:3|max:50","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'i-new-value-' + index,"size":"lg"},model:{value:(_vm.newValue[language].surname),callback:function ($$v) {_vm.$set(_vm.newValue[language], "surname", $$v)},expression:"newValue[language].surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-list-group-item',[_vm._v(" "+_vm._s(_vm.$t('ProfessionalTitle'))),_c('br'),_c('validation-provider',{attrs:{"name":"newValue[language]","rules":"required|max:200","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'i-new-value-' + index,"size":"lg"},model:{value:(_vm.newValue[language].professional_title),callback:function ($$v) {_vm.$set(_vm.newValue[language], "professional_title", $$v)},expression:"newValue[language].professional_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-list-group-item',[_vm._v(" "+_vm._s(_vm.$t('Mentor description'))),_c('br'),_c('validation-provider',{attrs:{"name":_vm.newValue[language].mentor_description,"rules":"required|max:1400","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea-with-counter',{staticStyle:{"font-size":"16px !important"},attrs:{"id":'i-mentor-description' + index,"locale":language,"max-chars":1400,"rows":"6"},model:{value:(_vm.newValue[language].mentor_description),callback:function ($$v) {_vm.$set(_vm.newValue[language], "mentor_description", $$v)},expression:"newValue[language].mentor_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }