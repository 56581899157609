<template>
  <b-row>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('Current value') }}
      </label>
      <template v-if="removal">
        <b-form-checkbox-group
          v-model="oldValue"
          stacked
        >
          <b-form-checkbox
            v-for="(localeInfo, index) in locales"
            :key="index"
            :value="localeInfo.locale"
            size="lg"
            disabled
          >
            {{ localeInfo.name }}
            <b-img
              :src="localeInfo.img"
              width="22"
              height="14"
            />
          </b-form-checkbox>
        </b-form-checkbox-group>
      </template>
    </b-col>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('New value') }}
      </label>
      <template v-if="removal">
        <b-form-checkbox-group
          v-model="newValue"
          stacked
        >
          <b-form-checkbox
            v-for="(localeInfo, index) in locales"
            :key="index"
            :value="localeInfo.locale"
            size="lg"
            disabled
          >
            {{ localeInfo.name }}
            <b-img
              :src="localeInfo.img"
              width="22"
              height="14"
            />
          </b-form-checkbox>
        </b-form-checkbox-group>
      </template>
      <template v-else-if="addition && newValue">
        <div
          v-for="(language, index) in Object.keys(newValue)"
          :key="index"
        >
          <validation-observer ref="pendingEditRequestMentoringLanguagesValuesObserver">
            <b-list-group>
              <b-list-group-item>
                {{ getSpecificLocale(language).locale }}
                <b-img
                  :src="getSpecificLocale(language).img"
                  width="22"
                  height="14"
                />
              </b-list-group-item>
              <b-list-group-item>
                {{ $t('Name') }}<br>
                <validation-provider
                  v-slot="{ errors }"
                  name="newValue[language]"
                  rules="required|min:3|max:50"
                  mode="lazy"
                >
                  <b-form-input
                    :id="'i-new-value-' + index"
                    v-model="newValue[language].name"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-list-group-item>
              <b-list-group-item>
                {{ $t('Surname') }}<br>
                <validation-provider
                  v-slot="{ errors }"
                  name="newValue[language]"
                  rules="required|min:3|max:50"
                  mode="lazy"
                >
                  <b-form-input
                    :id="'i-new-value-' + index"
                    v-model="newValue[language].surname"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-list-group-item>
              <b-list-group-item>
                {{ $t('ProfessionalTitle') }}<br>
                <validation-provider
                  v-slot="{ errors }"
                  name="newValue[language]"
                  rules="required|max:200"
                  mode="lazy"
                >
                  <b-form-input
                    :id="'i-new-value-' + index"
                    v-model="newValue[language].professional_title"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-list-group-item>
              <b-list-group-item>
                {{ $t('Mentor description') }}<br>
                <validation-provider
                  v-slot="{ errors }"
                  :name="newValue[language].mentor_description"
                  rules="required|max:1400"
                  mode="lazy"
                >
                  <textarea-with-counter
                    :id="'i-mentor-description' + index"
                    v-model="newValue[language].mentor_description"
                    :locale="language"
                    :max-chars="1400"
                    rows="6"
                    style="font-size: 16px !important;"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-list-group-item>
            </b-list-group>
          </validation-observer>
        </div>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BImg,
  BListGroup,
  BListGroupItem,
  BRow,
} from 'bootstrap-vue'
import TextareaWithCounter from '@mentoring-platform/views/components/blocks/TextareaWithCounter.vue'
import { useLocales } from '@mentoring-platform/composables'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    BImg,
    BListGroup,
    BListGroupItem,
    BRow,
    TextareaWithCounter,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: [],
      oldValue: [],
      removal: false,
      addition: false,
    }
  },
  created() {
    this.oldValue = this.request.old_value
    const newValue = JSON.parse(this.request.new_value)
    if (newValue.remove) {
      this.removal = true
      this.newValue = this.request.old_value.filter(language => !newValue.remove.includes(language))
    } else if (newValue.add) {
      this.addition = true
      this.newValue = newValue.add
    }
  },
  methods: {
    isValid() {
      return true
    },
    collectData() {
      return {
        new_value: this.newValue,
      }
    },
  },
  setup() {
    const { locales, getSpecificLocale } = useLocales()

    return {
      locales,
      getSpecificLocale,
    }
  },
}
</script>
